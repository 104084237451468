import Login from 'components/Login';
import Wrapper from 'components/Wrapper';

function LoginPage() {
  return (
    <Wrapper>
      <Login />
    </Wrapper>
  );
}

export default LoginPage;
