import React, { useContext } from 'react';
import instance from '../config/axios-instance';
import AuthContext from '../store/auth-context';
import AxiosContext from '../store/axios-context';

function Wrapper({ children }) {
  const axiosInstance = instance;
  const pageExists = axiosInstance !== null;

  const authCtx = useContext(AuthContext);
  if (authCtx.isLoggedIn) {
    axiosInstance.defaults.headers.common['Authorization'] =
      'Bearer ' + authCtx.token;
  }

  // 401 Interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
      error.response.status === 401 ? authCtx.logout() : Promise.reject(error)
  );

  return (
    <AxiosContext.Provider value={{ instance: axiosInstance }}>
      {pageExists ? children : ''}
    </AxiosContext.Provider>
  );
}

export default Wrapper;
