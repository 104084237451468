import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import logo from "assets/img/print-logo.png";
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "calc(100vh - 94px)",
  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'column',
  },
  header: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col6: {
    width: '50%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col7: {
    width: '65%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col5: {
    width: '35%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },

  bold: {
    fontWeight: '600',
    fontSize: 12,
  },
  dflex: {
    display: 'flex',
  },

  thcol: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  tdcol: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  description: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '50%'
  },
  total: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    marginTop: 1,
    marginRight: -1,
    width: '50%',
    textAlign: 'right'
  },
  tdBorderless: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginTop: 1,
    marginRight: -1,
  },

  marginY5: {
    marginTop: 25,
    marginBottom: 25
  },

  row60: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    marginBottom: 5
  },
  border: {
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 10,
    padding: 5,
    textAlign: 'center',
    width: 140,
    fontSize: 8,
    marginLeft: 50
  },

  withoutBorder: {
    marginLeft: 25,
    textAlign: 'center',
    width: 160,
    fontSize: 8,
  },
  leftContent: {
    flex: '1 1 auto',
    flexShrink: 0,
    width: '60%'
  },
  rightContent: {
    flex: '1 1 auto',
    marginLeft: 25,
  },

  padding10: {
    padding: 10,
  },

  bold: {
    fontFamily: 'Helvetica-Bold',
  }

});

function PodPdf() {
  const location = useLocation();
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const [podEditFormValues, setPodEditFormValues] = useState([{ job_number: "", description: "", quantity: 0, remark: "" }])
  const [podConsignee, setPodConsignee] = useState('');
  const [podOrigin, setPodOrigin] = useState('');
  const [podblAwb, setPodBlAwb] = useState('');
  const [podDate, setPodDate] = useState('');
  const [podVslFlt, setPodVslFlt] = useState('');
  const [podTransporter, setPodTransporter] = useState('');

  const [podSupervisor, setPodSupervisor] = useState('');
  const [podDeliveryClerk, setPodDeliveryClerk] = useState('');
  const [podSignName, setPodSignName] = useState('');
  const [podSignature, setPodSignature] = useState('');
  const [podSignDate, setPodSignDate] = useState('');
  const [podSignTime, setPodSignTime] = useState('');
  const [podTotalPackage, setPodTotalPackage] = useState(0);

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    // setData(location.state.data);
    getPod(location.state.data.job_id)

  }, []);

  const getPod = (job_id) => {
    // Call login API
    axiosCtx.instance.get(`pod-pdf/job/` + job_id)
      .then((response) => {
        // setData(response.data.data);
        setPodConsignee(response.data.data.consignee.name);
        setPodOrigin(response.data.data.origin.country_name);
        setPodBlAwb(response.data.data.b_l_awb);
        setPodDate(response.data.data.date);
        setPodVslFlt(response.data.data.vsl_flt);
        setPodTransporter(response.data.data.transporter);
        setPodSupervisor(response.data.data.supervisor);
        setPodDeliveryClerk(response.data.data.delivery_clerk);
        setPodTotalPackage(response.data.data.total_package);
        setPodEditFormValues(JSON.parse(response.data.data.dynamic_fields));
      })
      .catch((error) => {
      });
  }

  return (
    <>
      <div className="content pdfViewer">
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <Image style={styles.logo} src={logo} />
              </View>
              <Text style={[styles.bold, { textAlign: 'center', marginBottom: 20, fontSize: 14 }]}> DELIVERY NOTE  </Text>

              <View style={styles.row}>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Consignee :      </Text>
                    <Text style={styles.col6}> {podConsignee}     </Text>
                  </View>
                </Text>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Origin :      </Text>
                    <Text style={styles.col6}> {podOrigin}    </Text>
                  </View>
                </Text>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>B/L/AWB :      </Text>
                    <Text style={styles.col6}>  {podblAwb}     </Text>
                  </View>
                </Text>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Date :      </Text>
                    <Text style={styles.col6}>  {podDate}     </Text>
                  </View>
                </Text>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>VSL / FLT  :      </Text>
                    <Text style={styles.col6}> {podVslFlt}    </Text>
                  </View>
                </Text>
                <Text style={styles.col6}>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Transporter :      </Text>
                    <Text style={styles.col6}> {podTransporter}    </Text>
                  </View>
                </Text>


              </View>

              <View style={styles.marginY5} >
                <View style={styles.row} >
                  <Text style={[styles.thcol, styles.bold]}> Job Number / Marks  </Text>
                  <Text style={[styles.description, styles.bold]}>  Description of Packages  </Text>
                  <Text style={[styles.thcol, styles.bold]}> Quantity  </Text>
                  <Text style={[styles.thcol, styles.bold]}>Remarks </Text>
                </View>
                {podEditFormValues && podEditFormValues.map((element, index) => (
                  <View style={styles.row} >
                    <Text style={styles.tdcol}> {element.job_number ?? ''}  </Text>
                    <Text style={styles.description}> {element.description ?? ''} </Text>
                    <Text style={styles.tdcol}> {element.quantity ?? ''}  </Text>
                    <Text style={styles.tdcol}> {element.remark ?? ''} </Text>
                  </View>
                ))}

                <View style={styles.row} >
                  <Text style={styles.tdBorderless}>  </Text>
                  <Text style={styles.total}> Total Packages </Text>
                  <Text style={styles.tdcol}> {podTotalPackage}  </Text>
                  <Text style={styles.tdcol}> - </Text>
                </View>
              </View>


              <View style={styles.row}>
                <View style={styles.leftContent}>
                  <View style={styles.row60}>
                    <Text>Supervisor    </Text>
                    <Text style={styles.border}>
                      {podSupervisor}</Text>
                  </View>

                  <View style={styles.row60}>
                    <Text>Delivery Clerk  </Text>

                    <Text style={styles.withoutBorder}>
                      {podDeliveryClerk}</Text>
                  </View>
                </View>

                <View style={styles.rightContent}>
                  <Text style={styles.padding10}>  SIGNATURE OF CONSIGNEE   </Text>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Name : </Text>
                    <Text style={styles.col6}>----------------------------   </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Signature : </Text>
                    <Text style={styles.col6}>  ----------------------------     </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Date : </Text>
                    <Text style={styles.col6}>  ----------------------------     </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.col6}>Time : </Text>
                    <Text style={styles.col6}>  ----------------------------     </Text>
                  </View>
                </View>


              </View>


            </Page>
          </Document>
        </PDFViewer>
      </div>
    </>
  );
}

export default PodPdf;
