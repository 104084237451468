import PodPdf from 'components/Pdf/PodPdf';
import Wrapper from 'components/Wrapper';

function PodPdfPage() {
  return (
    <Wrapper>
      <PodPdf />
    </Wrapper>
  );
}

export default PodPdfPage;
