import AirwayBillPdf from 'components/Pdf/AirwayBillPdf';
import Wrapper from 'components/Wrapper';

function AirwayBillPdfPage() {
  return (
    <Wrapper>
      <AirwayBillPdf />
    </Wrapper>
  );
}

export default AirwayBillPdfPage;
