import axios from 'axios';

const instance = axios.create({
   baseURL: "http://abs.cordiacesolutions.com/backend/public/api",
   //baseURL: "http://shipping.cordiacesolutions.com/shipping-api/public/api",
  // baseURL: "http://127.0.0.1:8000/api",
  // baseURL: "http://sts.cordiacesolutions.com/backend/public/api",
  headers: { Accept: 'application/json' }, 
});


export default instance;
