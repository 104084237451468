import React, { useState } from 'react';

const AuthContext = React.createContext({
  token: '',
  role: '',
  name: '',
  isLoggedIn: false,
  login: (token) => {},
  userRole: (role) => {},
  userName: (name) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const retrievedToken = localStorage.getItem('token');
  const retrievedRole = localStorage.getItem('role');
  const retrievedName = localStorage.getItem('name');
  const [token, setToken] = useState(retrievedToken);
  const [role, setRole] = useState(retrievedRole);
  const [name, setName] = useState(retrievedName);
  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem('token');
    setRole(null);
    localStorage.removeItem('role');
    setName(null);
    localStorage.removeItem('name');
  };

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const userRoleHandler = (role) => {
    setRole(role);
    localStorage.setItem('role', role);
  };

  const userNameHandler = (name) => {
    setName(name);
    localStorage.setItem('name', name);
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        role: role,
        name: name,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        userRole: userRoleHandler,
        userName: userNameHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
