import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Stack from '@mui/material/Stack';

const InputDate = props => {
    return (
        <FormControl variant="outlined" className="w-100">
            <Stack component="form" noValidate spacing={3}>
                <TextField
                    size="small"
                    type="date"
                    id={props.id}
                    label={props.label}
                    value={props.value}
                    onChange={(e) => props.onChange(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
            {
                props.formError && (
                    <p className="text-danger small pt-2">
                        {props.formError.join('. ')}
                    </p>
                )}
        </FormControl>
    );
};

export default InputDate;

