// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button } from "react-bootstrap";
import { Row, Col, } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import ReactExport from "react-export-excel";
import Dropdown from "../Fields/Dropdown";
import InputDate from "../Fields/InputDate";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import moment from 'moment';
import { CSVLink } from "react-csv";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'cost-description',
    numeric: false,
    disablePadding: true,
    label: 'Cost Description',
  },
  {
    id: 'job-id',
    numeric: false,
    disablePadding: false,
    label: 'Job Id',
  },
  {
    id: 'Supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
  },
  {
    id: 'ReferenceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Reference Number',
  },
  {
    id: 'vat',
    numeric: false,
    disablePadding: false,
    label: 'VAT',
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: 'Grand Total',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CostReport() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  const [status, setStatus] = useState(null);
  const [subtype, setSubtype] = useState(null);
  const [project, setProject] = useState(null);
  const [jobtype, setJobtype] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [job, setJob] = useState(null);
  const [formError, setFormError] = useState(null);

  const [jobtypes, setJobtypes] = useState([]);
  const [parties, setParties] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [costs, setCosts] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const [internationalVat, setInternationalVat] = useState(false);

  const startDateChangeHandler = (event) => {
    setStartDate(event.target.value);
  };

  const endDateChangeHandler = (event) => {
    setEndDate(event.target.value);
  };

  const internationalVatHandleChange = (event) => {
    setInternationalVat(event.target.checked);
  }

  const getCosts = () => {
    axiosCtx.instance.get(`cost`)
      .then((response) => {
        setCosts(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobs = () => {
    axiosCtx.instance.get(`job`)
      .then((response) => {
        setJobs(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };


  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getCosts();
    getParties();
    getJobs();

  }, []);

  const filterClear = () => {
    setStartDate('');
    setEndDate('');
    setSupplier(null);
    setJob(null);
    setInternationalVat(false);
    getCosts();
  }


  const filterSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    setFormError(null);

    // Call login API
    const formData = new FormData();
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("job_id", (job) ? job.id : '');
    formData.append("international_vat", (internationalVat == true) ? 1 : 2);
    formData.append("supplier_id", (supplier) ? supplier.id : '');

    axiosCtx.instance.post(`cost/filter`, formData)
      .then((response) => {
        setCosts(response.data.data);
        filterClose();
        setLoading(false);
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = costs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();


    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
    // setStatus('');
    // setJobtype('');
    // setProject('');
    // setSupplier('');
    // setStartDate('');
    // setEndDate('');
    // getJobs();
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - costs.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");
  let [costData, setCostData] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const sortCosts = () => {
    let data = [...costs];
    data = data.map(row => (
      {
        ...row,
        cost_date: row.cost_date ? moment(row.cost_date).format("YYYY-MM-DD") : '',
        cost_description: row.cost_description ? row.cost_description.name : "",
        airwaybill_localorder: row.airwaybill_localorder.mawb_mcbv_no ? row.prefix.prefix + row.airwaybill_localorder.mawb_mcbv_no : row.airwaybill_localorder.order_number,
        job: row.job ? row.job.job_id : "",
        supplier: row.supplier ? row.supplier.name : "",
        cost_amount: row.cost_amount ? parseFloat(row.cost_amount).toFixed(2) - parseFloat(0) : 0,
        cost_vat: row.cost_amount ? parseFloat(row.cost_amount * (row.cost_vat / 100)).toFixed(2) - parseFloat(0) : 0,
        cost_total: row.cost_total ? parseFloat(row.cost_total).toFixed(2) - parseFloat(0) : 0,
        international_vat: (row.international_vat && row.international_vat == 1) ? "Yes" : "No",
      }
    ));
    setCostData(data);
    console.log(data);
  }

  const headers = [
    { label: "Date", key: "cost_date" },
    { label: "Cost Description", key: "cost_description" },
    { label: "AWB No/Local Order No", key: "airwaybill_localorder" },
    { label: "Job ID", key: 'job' },
    { label: "Supplier", key: 'supplier' },
    { label: "Amount Before tax", key: 'cost_amount' },
    { label: "VAT", key: 'cost_vat' },
    { label: "Grand Total", key: 'cost_total' },
    { label: "Inernational tax Excempt", key: 'international_vat' },
  ];



  return (
    <div className="content">
      {loading && loading == true ?
        <div className="custom-loader">
          <FadeLoader color={color} loading={loading} css={override} size={150} />
        </div>
        : ''
      }
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Cost Report</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            <CSVLink data={costData} headers={headers} className="btn btn-simple" onClick={event => {
              sortCosts();
              return true;
            }}>
              Download
            </CSVLink>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="From Date"
                      label="From Date"
                      value={startDate}
                      onChange={startDateChangeHandler}
                    />

                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="To Date"
                      label="To Date"
                      value={endDate}
                      onChange={endDateChangeHandler}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="Supplier"
                      placeholder="Supplier"
                      label="name"
                      options={parties}
                      value={supplier}
                      onChange={(event, newValue) => {
                        setSupplier(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox
                        checked={internationalVat}
                        onChange={internationalVatHandleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      } label="International VAT Excepted" />
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="jobid"
                      placeholder="Job ID"
                      label="job_id"
                      options={jobs}
                      value={job}
                      onChange={(event, newValue) => {
                        setJob(newValue);
                      }}
                    />
                  </Col>
                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>


      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={costs.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(costs, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none" > {row.cost_date} </TableCell>
                        <TableCell>{row.cost_description.name}</TableCell>
                        <TableCell>{row.job.job_id}</TableCell>
                        <TableCell>{row.supplier.name}</TableCell>
                        <TableCell>{row.reference_number}</TableCell>
                        <TableCell>{row.cost_amount * (row.cost_vat / 100)}</TableCell>
                        <TableCell>{row.cost_total}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={costs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>

    </div>
  );
}
