import JobReport from 'components/Reports/JobReport';
import Wrapper from 'components/Wrapper';

function JobReportPage() {
  return (
    <Wrapper>
      <JobReport />
    </Wrapper>
  );
}

export default JobReportPage;
