import JobList from 'components/JobList';
import Wrapper from 'components/Wrapper';

function JobPage() {
  return (
    <Wrapper>
      <JobList />
    </Wrapper>
  );
}

export default JobPage;
