import Invoice from 'components/Invoice';
import Wrapper from 'components/Wrapper';

function InvoicePage() {
  return (
    <Wrapper>
      <Invoice />
    </Wrapper>
  );
}

export default InvoicePage;
