import OperationalCost from 'components/OperationalCost';
import Wrapper from 'components/Wrapper';

function OperationalCostPage() {
  return (
    <Wrapper>
      <OperationalCost />
    </Wrapper>
  );
}

export default OperationalCostPage;
