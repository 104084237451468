import InvoiceReport from 'components/Reports/InvoiceReport';
import Wrapper from 'components/Wrapper';

function InvoiceReportPage() {
  return (
    <Wrapper>
      <InvoiceReport />
    </Wrapper>
  );
}

export default InvoiceReportPage;
