import ExitCertificatePdf from 'components/Pdf/ExitCertificatePdf';
import Wrapper from 'components/Wrapper';

function ExitCertificatePdfPage() {
  return (
    <Wrapper>
      <ExitCertificatePdf />
    </Wrapper>
  );
}

export default ExitCertificatePdfPage;
