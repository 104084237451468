// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import { Button, Modal } from "react-bootstrap";
// reactstrap components
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import { Row, Col, } from "reactstrap";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { CSVLink } from "react-csv";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Cost Description',
  },
  {
    id: 'paid_to',
    numeric: false,
    disablePadding: false,
    label: 'Paid To',
  },
  {
    id: 'total_cost',
    numeric: false,
    disablePadding: false,
    label: 'Total Cost Including VAT',
  },
  {
    id: 'Date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function OperationalCost() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  const [status, setStatus] = useState('');
  // const [jobcode, setJobcode] = useState('');
  const [formError, setFormError] = useState(null);
  const [operationalCosts, setOperationalCosts] = useState([]);

  const [costId, setCostId] = useState('');
  const [costDescriptions, setCostDescriptions] = useState([]);
  const [costDescription, setCostDescription] = useState(null);

  const [parties, setParties] = useState([]);
  const [paidTo, setPaidTo] = useState(null);
  const [totalCost, setTotalCost] = useState('');
  const [vat, setVat] = useState('');
  const [excludingVat, setExcludingVat] = useState('');
  const [costDate, setCostDate] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const getOperationalCosts = () => {
    setLoading(true);
    axiosCtx.instance.get(`operational-cost`)
      .then((response) => {
        setOperationalCosts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        setLoading(false);
      });
  };

  const getCostDescriptions = () => {
    axiosCtx.instance.get(`cost-description`)
      .then((response) => {
        setCostDescriptions(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getOperationalCosts();
    getCostDescriptions();
    getParties();
  }, []);

  const onCostEditHandler = (item) => {
    setCostId(item.id);
    setCostDescription(item.cost_description);
    setPaidTo(item.paid_to);
    setTotalCost(item.total_cost);
    setCostDate(item.cost_date);
    setVat(item.vat);
    setExcludingVat(item.total_cost_excluding_vat);
    handleShow();
  };

  const onCostDeleteHandler = (item) => {
    setDeleteId(item);
    handleShowDelete();
  };

  const deleteSubmitHandler = () => {
    setLoading(true);
    if (deleteId) {
      console.log(deleteId);
      axiosCtx.instance.delete(`operational-cost/` + deleteId,)
        .then((response) => {
          setLoading(false);
          setDeleteMessage(response.data.data);
          setDeleteId('');
          handleCloseDelete();
          getOperationalCosts();
        })
        .catch((error) => {
          setLoading(false);
          setDeleteMessage(error.response.data.errors);
        });
    }
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteMessage('');
    setDeleteId('');

  }
  const handleShowDelete = () => setShowDelete(true);

  const submitHandler = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);

    // Call login API
    const params = {
      cost_description: (costDescription) ? costDescription.id : '',
      paid_to: (paidTo) ? paidTo.id : '',
      total_cost: totalCost,
      vat: vat,
      total_cost_excluding_vat: excludingVat,
      cost_date: costDate,
    };

    if (costId) {

      axiosCtx.instance.put(`operational-cost/` + costId, params)
        .then((response) => {
          getOperationalCosts();
          handleClose();
          setLoading(false);
        })
        .catch((error) => {
          setFormError(error.response.data);
          setLoading(false);
        });
    }
    else {
      axiosCtx.instance.post(`operational-cost`, params)
        .then((response) => {
          setLoading(false);
          getOperationalCosts();
          handleClose();
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error.response.data);
        });
    }

  };

  const filterSubmit = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);
    const params = {
      start_date: startDate,
      end_date: endDate,
    };

    axiosCtx.instance.post(`operational-cost/filter`, params)
      .then((response) => {
        setLoading(false);
        setOperationalCosts(response.data.data);
        filterClose();
      })
      .catch((error) => {
        setLoading(false);
        setFormError(error.response.data);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = operationalCosts.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();


    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCostId(null);
    setFormError(null);
    setShow(false);
    setCostDescription(null);
    setPaidTo(null);
    setTotalCost('');
    setVat('');
    setExcludingVat('');
    setCostDate('');
  }
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
  }
  const filterClear = () => {
    setStartDate('');
    setEndDate('');
    getOperationalCosts();
  }

  const onBlurTotalCost = () => {
    if ((vat && vat > 0) && (totalCost && totalCost > 0)) {
      var vat1 = parseInt(100) + parseFloat(vat);
      var vat2 = vat1 / 100;
      var totalExlucdingVat = totalCost / vat2;
      setExcludingVat(totalExlucdingVat.toFixed(2));
    }
    else {
      setExcludingVat(totalCost);
    }

  }

  const onBlurVat = () => {
    if ((vat && vat > 0) && (totalCost && totalCost > 0)) {
      var vat1 = parseInt(100) + parseFloat(vat);
      var vat2 = vat1 / 100;
      var totalExlucdingVat = totalCost / vat2;
      setExcludingVat(totalExlucdingVat.toFixed(2));
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - operationalCosts.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  

  let [excelData, setExcelData] = useState([]);
  const sortCost = () => {
    let data = [...operationalCosts];
    data = data.map(row => (
      {
        ...row,
        cost_description: row.cost_description ? row.cost_description.name : "",
        vat: row.vat ? parseFloat(row.vat).toFixed(2) - parseFloat(0) : 0,
        paid_to: row.paid_to ? row.paid_to.name : "",
        total_cost: row.total_cost ? parseFloat(row.total_cost).toFixed(2) - parseFloat(0) : 0,
        cost_date: row.cost_date ? moment(row.cost_date).format("YYYY-MM-DD") : '',
        total_cost_excluding_vat: row.total_cost_excluding_vat ? parseFloat(row.total_cost_excluding_vat).toFixed(2) - parseFloat(0) : 0,
      }
    ));
    setExcelData(data);
  }

  const headers = [
    { label: "Cost Description", key: "cost_description" },
    { label: "Paid To", key: "paid_to" },
    { label: "Total Cost Including VAT", key: "total_cost" },
    { label: "VAT", key: "vat" },
    { label: "Total Cost Excluding VAT", key: 'total_cost_excluding_vat' },
    { label: "Status", key: 'status' },
    { label: "Date", key: 'cost_date' },
  ];

  return (
    <div className="content">
      {loading && loading == true ?
        <div className="custom-loader">
          <FadeLoader color={color} loading={loading} css={override} size={150} />
        </div>
        : ''
      }
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Operational Cost List</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            <CSVLink data={excelData} headers={headers} className="btn btn-simple" onClick={event => {
              sortCost();
              return true;
            }}>
              Download
            </CSVLink>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                  <Col md="6" className="mb-4">
                    <TextField type="date" size="small" className="w-100" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" variant="outlined" InputLabelProps={{
                      shrink: true,
                    }} />
                    {formError &&
                      formError.errors &&
                      formError.errors.start_date && (
                        <p className="text-danger small pt-2">
                          {formError.errors.start_date.join('. ')}
                        </p>
                      )}
                  </Col>
                  <Col md="6" className="mb-4">
                    <TextField type="date" size="small" className="w-100" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" variant="outlined" InputLabelProps={{
                      shrink: true,
                    }} />
                    {formError &&
                      formError.errors &&
                      formError.errors.end_date && (
                        <p className="text-danger small pt-2">
                          {formError.errors.end_date.join('. ')}
                        </p>
                      )}
                  </Col>
                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-auto my-1">
            <button className="btn btn-primary" onClick={handleShow}>
              Create new
            </button>
          </div>
        </div>

        {/* ******************** modal start here **************** */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Create a new Operational Cost </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Row>

              <Col md="6" className="my-4">
                <FormControl variant="outlined" className="w-100">
                  <Autocomplete
                    value={costDescription || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setCostDescription(newValue);
                    }}
                    disablePortal
                    id="party-box-demo"
                    options={costDescriptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Cost Description" />}
                  />
                  {formError &&
                    formError.errors &&
                    formError.errors.cost_description && (
                      <p className="text-danger small pt-2">
                        {formError.errors.cost_description.join('. ')}
                      </p>
                    )}
                </FormControl>

              </Col>
              <Col md="6" className="my-4">
                <FormControl variant="outlined" className="w-100">
                  <Autocomplete
                    value={paidTo || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setPaidTo(newValue);
                    }}
                    disablePortal
                    id="party-box-demo"
                    options={parties}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Paid To" />}
                  />
                  {formError &&
                    formError.errors &&
                    formError.errors.paid_to && (
                      <p className="text-danger small pt-2">
                        {formError.errors.paid_to.join('. ')}
                      </p>
                    )}


                </FormControl>
              </Col>

              <Col md="6" className="my-4">
                <TextField
                  size="small"
                  type="number"
                  className="w-100"
                  onChange={(e) =>
                    setTotalCost(e.target.value)
                  }
                  onBlur={onBlurTotalCost}
                  value={totalCost}
                  label="Total Cost Including VAT"
                  variant="outlined" />
                {formError &&
                  formError.errors &&
                  formError.errors.total_cost && (
                    <p className="text-danger small pt-2">
                      {formError.errors.total_cost.join('. ')}
                    </p>
                  )}
              </Col>

              <Col md="6" className="my-4">
                <TextField
                  size="small"
                  type="number"
                  className="w-100"
                  onChange={(e) => setVat(e.target.value)}
                  onBlur={onBlurVat}
                  value={vat}
                  label="VAT"
                  variant="outlined" />
                {formError &&
                  formError.errors &&
                  formError.errors.vat && (
                    <p className="text-danger small pt-2">
                      {formError.errors.vat.join('. ')}
                    </p>
                  )}
              </Col>
              <Col md="6" className="my-4">
                <TextField type="number" size="small" className="w-100" value={excludingVat} label="Total Cost Excluding VAT" variant="outlined" />
              </Col>
              <Col md="6" className="my-4">
                <TextField type="date" size="small" className="w-100" onChange={(e) => setCostDate(e.target.value)} value={costDate} label="Cost Date" variant="outlined" InputLabelProps={{
                  shrink: true,
                }} />
                {formError &&
                  formError.errors &&
                  formError.errors.cost_date && (
                    <p className="text-danger small pt-2">
                      {formError.errors.cost_date.join('. ')}
                    </p>
                  )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={submitHandler}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ******************** delete modal start here **************** */}
        <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Warehouse </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">Are you sure to do this ? </Modal.Title>
            <p>{deleteMessage}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={deleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={operationalCosts.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(operationalCosts, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" className="selectCheckbox">
                          {/* <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            className="selectCheckbox"
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> */}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" > {row.cost_description.name} </TableCell>
                        <TableCell>{(row.paid_to) ? row.paid_to.name : ''}</TableCell>
                        <TableCell>{row.total_cost}</TableCell>
                        <TableCell>{row.cost_date}</TableCell>
                        <TableCell><button onClick={() => onCostEditHandler(row)} className="btn btn-primary btn-sm font-weight-bold ">Edit</button>
                          <button onClick={() => onCostDeleteHandler(row.id)} className="btn btn-danger btn-sm font-weight-bold ">Delete</button></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={operationalCosts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>
    </div>
  );
}
