import WarehouseEdit from 'components/WarehouseEdit';
import Wrapper from 'components/Wrapper';

function WarehouseEditPage() {
  return (
    <Wrapper>
      <WarehouseEdit />
    </Wrapper>
  );
}

export default WarehouseEditPage;
